import React,{ Component } from "react";
import { Link  } from 'react-router-dom';
//import Keyboard from "react-simple-keyboard";
//import NumericInput from 'react-numeric-input';
import "react-simple-keyboard/build/css/index.css";
import InfiniteScroll from "react-infinite-scroll-component";
//import IncDecCounter from "../../components/IncDecCounter";

import Button from 'react-bootstrap/Button';
//import Container from 'react-bootstrap/Container';
import Modal from 'react-bootstrap/Modal';

import BarcodeScannerComponent from "react-qr-barcode-scanner";
  
const scanned_items = {
  height: 40,
  border: "1px solid green",
  margin: 3,
  padding: 3,
  background:"green"
};

const scanned_pending = {
  height: 40,
  border: "1px solid green",
  margin: 3,
  padding: 3,
  background:"rgb(132, 126, 126)",
  color:"#ffffff"
};

//let fromPage = 'stockTransfer';



class StockMovementBinLoc extends Component {

  state = {
    items: [],
    Fixeditems: [],
    BinEnabledWH: [],
    itemsShow:{ display: 'block' },
    filterItemsShow:{ display: 'none' },
    itemDet: [],
    spinner:false,
    hasQty: false,
    upScanQty: false,
    totalOrderQty: 0,
    qtyButton: JSON.parse(sessionStorage.getItem('currentLang'))[66],
    fullScrenButton: 'Full Screen',
    handlefindItem: this.findItem.bind(this, 'DocumentItemFind'),
    //handlefindItemInput:this.findItem.bind(this, 'DocumentItemFindInput'),
    total_scanned_item:0,
    scanned_ean_code:null,
    hasFullScrn: false,
    visibility: "block",
    layoutName: "default",
    input: "",
    input3:"",
    qtyInput:1,
    keyboardVisibility:false,
    keyboardVisibilityQty:false,
    keyboardVisibilityScanQty:false,
    itemSelectedPrevId:0,
    itemSelectedPrevQty:0,
    itemSelected:false,
    itemSelectedAvailQty:0,
    itemSelectedBgColor:'tapping_bg_color',
    modalShow:false,
    incnum: "",
    maxBinQtySelect:0,
    selectedBinLocQty:[],
    searchedData:[],
    selectedDistNumbers:[],
    selectedActBinLoc:[],
    selectedBinData:[],
    selectedActDistNum:[],
    //selectedActDistBinNum:[],
    customSelectedBinQty:[],
    selectedBinLocName:"",
    selectedBatchName:"",
    selectedFromBatch:"",
    webcamResult:"Not Found",
    setStopStream:false,
    batchNumbers:"",
    setPrevPage:"",
    directCommentScreen:false,
    totalBinQty:0,
    fromPage:localStorage.getItem('pageFrom')?localStorage.getItem('pageFrom'):'stockTransfer',
    isBatchEnabled:this.props.match.params.isPolskyNumber,
    binUpdateStatus:0,
    selectedDefVLoc:"",
    
  };

  findStockQtyBinLoc() {
   
    //if (event.charCode === 13) { for enter key
    let OptionId = this.props.match.params.optionId;    
    const itemId = this.props.match.params.id;
    const isPolskyNum = this.props.match.params.isPolskyNumber;
    const itemCode = this.props.match.params.itemcode;   
    //let fromPage = localStorage.getItem('pageFrom');  
    this.setState({fromPage: localStorage.getItem('pageFrom')});
    
    
    let ajaxUrl = '/api/v1/fetchBinLocQty';
    let backUrl ='';
    console.log( localStorage.getItem('pageFrom'));
    if(String(itemId) === 'stockcounting'){
      //fromPage = 'stockcounting';
      this.setState({fromPage: 'stockcounting'});
      ajaxUrl = '/api/v1/fetchBinLocQtyStockCounting';
    }
    
//alert(localStorage.getItem('StockTransferBinBatch'));

 
//alert(this.state.fromPage);
      


 
  if(String(this.state.fromPage) === 'stockmovementitem' && parseInt(OptionId) === 2 ){
    
   //console.log( objectForNewDoc);
    
    backUrl = "/stockmovementitem/"+OptionId+'/'+localStorage.getItem('StockTransferItemDocEntry')+'/'+localStorage.getItem('StockTransferItemDocEntry');
    }
  else if(String(itemId) === 'stockcounting' && parseInt(OptionId) === 2 ){
    backUrl = "/stockcountinglist";
  }
  else if(String(itemId) === 'stockcounting' && parseInt(OptionId) === 3 ){
    backUrl = "/itemwisewarehousestock/stockcountinglist/"+OptionId;
  }
  else if(String(this.state.fromPage) === 'stockTransfer' && parseInt(OptionId) === 2 ){
    let currntdocEntry = !localStorage.getItem('StockTransferItemDocEntry')?localStorage.getItem('StockTransferMultipleDocEntry'):localStorage.getItem('StockTransferItemDocEntry');

    
    backUrl = "/stockdocdetails/2/"+currntdocEntry+"/"+currntdocEntry;
    //alert(currntdocEntry);

  }

  else if(String(localStorage.getItem('pageFrom')) === 'goodsreceive' && parseInt(OptionId) === 3 ){
   
    backUrl = "/Stockmovementitem/3/"+localStorage.getItem('StockTransferItemDocEntry')+"/"+localStorage.getItem('StockTransferItemDocEntry');
  }
 
//alert(localStorage.getItem('StockTransferItemDocEntry'));
  this.setState({setPrevPage: backUrl});
  //console.log( backUrl);
      
      
      

    const jsonbody =  {'itemId':itemId,'isPolsky':isPolskyNum,'itemCode':itemCode,'pageFrom':this.state.fromPage,'wareHouseCode':localStorage.getItem('StockTransferItemFromWH'), 
    'StockTransferItemToWH':localStorage.getItem('StockTransferItemToWH'),
    BaseEntry:localStorage.getItem('BaseEntry'),
    AbsEntry:localStorage.getItem('AbsEntry')
  };
    
  //console.log( jsonbody);

    //alert(ajaxUrl);
    this.setState({spinner: true});
    if(itemId.length > 0){
              fetch(ajaxUrl, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Token ${localStorage.getItem('token')}`
                },
                body:JSON.stringify(jsonbody)
              })
                .then(res => res.json())
                .then(data => {
                  console.log( data);
                  let BinQty = 0;
                  let Binrows = [];
                  for (let i = 0; i < data.docItemListLength; i++) {
                      // note: we are adding a key prop here to allow react to uniquely identify each
                      // element in this array. see: https://reactjs.org/docs/lists-and-keys.html
                      Binrows.push(data.docItemList[i]);
                      BinQty = BinQty+parseFloat(data.docItemList[i]['OnHandQty']);
                  }
                  this.setState({totalBinQty: BinQty});
                  //console.log( Binrows);
                  if(String(this.state.fromPage) !== 'goodsreceive' && !localStorage.getItem('StockTransferItemToWH')){
                  localStorage.setItem('StockTransferItemQty',0);
                  }

                  this.setState({spinner: false});
                  
                // console.log( data.docItemList.replace("/",""));
                //console.log( localStorage.getItem('StockTransferItemQtyForNewDoc'));
                //alert(localStorage.getItem('StockTransferItemDocEntry'));
                  this.setState({totalOrderQty: data.itemDet.qtyInputuantity});
                  //let availQty = parseFloat(data.itemDet.quantity) - 0;
                  if(String(this.state.fromPage) === 'stockmovementitem' && parseInt(OptionId) === 2 ){
                    this.setState({itemSelectedAvailQty: localStorage.getItem('StockTransferItemQtyForNewDoc')});
                  }
                  else if(String(this.state.fromPage) === 'goodsreceive' && parseInt(OptionId) === 3 ){
                    //console.log( this.state.fromPage);
                    this.setState({itemSelectedAvailQty: localStorage.getItem('StockTransferItemQtyForNewDoc')});
                    //let defaultBin = localStorage.getItem('StockTransferDefaultBin');
                    let defaultBin ='';
                    localStorage.setItem('StockTransferDefaultBinQty','');
                    
                    if(data.BinEnabledWH.length >0){
                      //localStorage.setItem('StockTransferDefaultBin',defaultBin);
                      let selectedDefVBinLoc = data.BinEnabledWH.filter(
                        binLocND => binLocND.BinCode.indexOf('-V-') !== -1
                      );
                      //console.log( selectedDefVBinLoc[0]['BinCode']);

                      //defaultBin =data['BinEnabledWH'][0]['BinCode'];
                      defaultBin = selectedDefVBinLoc[0]['BinCode'];
		      this.setState({selectedDefVLoc: defaultBin});
                      //localStorage.setItem('StockTransferDefaultBinQty',data['BinEnabledWH'][0]['OnHandQty']);
                      localStorage.setItem('StockTransferDefaultBinQty',selectedDefVBinLoc[0]['OnHandQty']);
                      }
                      
                     
                    let selectedBinLocD = Binrows.filter(
                      binLoc => binLoc.BinCode.indexOf(defaultBin) !== -1
                      

                    );
                    //localStorage.setItem('StockTransferDefaultBinQty',binLoc.OnHandQty);
                    //Binrows.push(selectedBinLocD);
                    //this.setState({Binrows: selectedBinLoc});
                    //BinrowsN = selectedBinLocN;
                    console.log( defaultBin);
                    

                    let fltrWith = "-N-";
                    let selectedBinLoc = Binrows.filter(
                      binLocN => binLocN.BinCode.indexOf(fltrWith) !== -1
                    );
                    //this.setState({Binrows: selectedBinLoc});
                   // Binrows.push(selectedBinLoc);
                    Binrows = selectedBinLocD.concat(selectedBinLoc);
                    //console.log( selectedBinLoc);
                  }
                  else{
                  this.setState({itemSelectedAvailQty: data.itemDet.Quantity});
                  }
                  
                  localStorage.setItem('StockTransferItemToBin',""); // Always reset To Bin Code
                 //alert(data.itemDet.quantity)
                  this.setState({itemDet: data.itemDet, items: Binrows, Fixeditems:Binrows, BinEnabledWH:data.BinEnabledWH});
                  if(String(this.state.fromPage) === 'stockTransfer' && parseInt(OptionId) === 1 ){
                    localStorage.setItem('StockTransferDocNum',this.state.itemDet.DocNum);
                    localStorage.setItem('ifCheckCurrentStockItems',0)
                  //backUrl = "/stockdocdetails/"+OptionId+'/'+this.state.itemDet.DocNum+'/'+this.state.itemDet.DocEntry;
                  backUrl = "/stockdocdetails/"+OptionId+'/0/'+localStorage.getItem('StockTransferMultipleDocEntry');
                  this.setState({setPrevPage: backUrl});
                  }
                  /*if(fromPage === 'stockTransfer' && parseInt(OptionId) === 1 ){
                    localStorage.setItem('StockTransferDocNum',this.state.itemDet.DocNum);
                    localStorage.setItem('ifCheckCurrentStockItems',0)
                  backUrl = "/stockdocdetails/"+OptionId+'/'+this.state.itemDet.DocNum+'/'+this.state.itemDet.DocEntry;
                  }
                  else if(fromPage === 'stockTransfer' && parseInt(OptionId) === 2 ){
                    backUrl = "/stockmovementitem/"+OptionId+'/'+localStorage.getItem('StockTransferItemDocEntry')+'/'+localStorage.getItem('StockTransferItemDocEntry');
                    }

                  else if(fromPage === 'stockcounting'){
                    backUrl = "/warehouse/stockcountinglist/"+OptionId;
                  }
              //alert(String(itemId));
                  this.setState({setPrevPage: backUrl});*/
                 
                 

                  //const data2 = JSON.parse('{"name":"John", "age":30, "city":"New York"}');
                //console.log( data.docItemList[0]['Items/ItemWarehouseInfoCollection']);
                //console.log( this.state.items[0]['Items/ItemWarehouseInfoCollection']);



                   //console.log( this.state.itemDet.documentslist_id)
                //this.setState({ docItem: data.document, items: data.docItemList, total_scanned_item: data.total_scanned_item });
               
                
              }
              ).catch(error => {
                
                console.log( error);
                this.setState({spinner: false});
            });
    
      } //if
    
    };

    

    handleSearch = event => {

      
      //console.log( users);*/
      console.log( this.state.selectedBinLocQty);
      //console.log( this.state.items);
      let serachTerm = event.target.value.toUpperCase();

      this.highlightItem(serachTerm);

    }; 

    highlightItem(serachTerm) {
      /*let allItems = this.state.Fixeditems;
      const selectedBinLoc = allItems.filter(
        binLoc => binLoc.BinCode.indexOf(serachTerm) !== -1
      );
      console.log( serachTerm);
      //setSearchedData(data);
      //const data3 = users.filter(
        //user => user.name.indexOf(event.target.value) !== -1
      //); Fixeditems
      this.setState({ searchedData: selectedBinLoc });*/


      let allItems = this.state.items;
      
      if (serachTerm === "") { this.setState({ items: this.state.Fixeditems }); return ; }
       
      let filterBySearch = allItems.filter((item) => {
       if (item.BinCode != null && item.BinCode.toLowerCase().includes(serachTerm.toLowerCase())) { return item; }
      else if (item.DistNumber != null && item.DistNumber.toLowerCase().includes(serachTerm.toLowerCase())) 
      { return item; }    
           
   })
   if(filterBySearch.length <=0){
     filterBySearch = this.state.Fixeditems
   }
   this.setState({ items: filterBySearch });

   console.log( filterBySearch);
        
      /*if(this.state.searchedData.length <=0){
      for (var j=0; j < this.state.selectedBinLocQty; j++) {
        if(document.getElementById('itemTap'+this.state.selectedBinLocQty[j]) != null){           
        document.getElementById('itemTap'+this.state.selectedBinLocQty[j]).setAttribute("style","color:#ffffff;height: 70px; border: 1px solid green; margin: 3px; padding: 3px; background: blue none repeat scroll 0% 0%;");
        }
        if(document.getElementById('itemTap2-'+this.state.selectedBinLocQty[j]) != null){           
          document.getElementById('itemTap2-'+this.state.selectedBinLocQty[j]).setAttribute("style","color:#ffffff;height: 70px; border: 1px solid green; margin: 3px; padding: 3px; background: blue none repeat scroll 0% 0%;");
          }
      }
    }
    else{
      for (var k=0; k < this.state.selectedBinLocQty; k++) {
        if(document.getElementById('itemTap'+this.state.selectedBinLocQty[k]) != null){           
        document.getElementById('itemTap'+this.state.selectedBinLocQty[k]).setAttribute("style","color:#ffffff;height: 70px; border: 1px solid green; margin: 3px; padding: 3px; background: blue none repeat scroll 0% 0%;");
        }
        if(document.getElementById('itemTap2-'+this.state.selectedBinLocQty[k]) != null){           
          document.getElementById('itemTap2-'+this.state.selectedBinLocQty[k]).setAttribute("style","color:#ffffff;height: 70px; border: 1px solid green; margin: 3px; padding: 3px; background: blue none repeat scroll 0% 0%;");
          }
        
      }      
    }*/

      
      //console.log( selectedBinLoc);
      
    }; 

    /*showSearchedData = () => {
      return this.state.searchedData.map(binLoc => (
        <div key={binLoc.BinCode}>

          <p id="mailid">{binLoc.BinCode} </p>
        </div>
      ));
      }*/
      
      tapToQtyEdit = () => {
      
      //this.setState({ scanned_ean_code: eancode })
     // alert(2);
      //if(this.state.scanned_ean_code.length > 8){
        //this.findItem.bind(this.state.scanned_ean_code.length, 'DocumentItemFind');
        //alert(eancode);
        //return 1;
          // }
          this.setState({ upScanQty: false });
          this.setState({ itemSelected: true });
          this.setState({modalShow: true});        
          
          /*const focusField = document.querySelector(
            `input[name=increamentValue]`
          );      
            focusField.focus();*/

        };

   
  
  tapToUpdateScannedItemQty = () => {    
    this.setState({ hasQty: false });
    if(this.state.itemSelected === true){ 

          this.setState({upScanQty: true});
          //alert(this.state.input3);
          //alert(this.state.totalOrderQty);
        if(this.state.input3 > 0 ){ 
          //if(this.state.input3 <= this.state.totalOrderQty  ){ 
          fetch('/api/v1/documents/ScanItemQtyUpdate/'+this.state.itemSelectedPrevId+'/'+this.state.input3, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Token ${localStorage.getItem('token')}`
            }
          })
          .then(res => res.json())
          .then(data => {
            //console.log( data);
            this.findDocument();                             
                    
                      //window.location.replace('/pickandpack');
                    });  
           //}
           //else{
            //alert( JSON.parse(sessionStorage.getItem('currentLang'))[43]);
           //}      
        }      
      else{
        alert( JSON.parse(sessionStorage.getItem('currentLang'))[42]);
      }
    }
    else{
      alert( JSON.parse(sessionStorage.getItem('currentLang'))[20]);
    }
    //this.findDocument();
  };   
  
  selectDestWareHouse = () => {
///alert(localStorage.getItem('StockTransferItemToWH'));
    if(!localStorage.getItem('StockTransferItemToWH')){
      console.log( localStorage.getItem('StockTransferItemFromBin'));   
      //alert("s");
          if(this.state.incnum > 0 ){
            //const itemId = this.props.match.params.id;
            //const isPolskyNum = this.props.match.params.isPolskyNumber;
            //const itemCode = this.props.match.params.itemcode;      
            localStorage.setItem('StockTransferItemId',this.props.match.params.id);
            localStorage.setItem('StockTransferItemIsPolsky',this.props.match.params.isPolskyNumber);
            localStorage.setItem('StockTransferItemCode',this.props.match.params.itemcode);
            localStorage.setItem('StockTransferItemQty',this.state.incnum);
            if(String(this.state.fromPage) === 'stockTransfer' || String(this.state.fromPage) === 'stockmovementitem'  || String(this.state.fromPage) === 'goodsreceive'){
              localStorage.setItem('StockTransferItemQty',this.state.total_scanned_item);
            //}
            //if(String(this.state.fromPage) === 'stockTransfer' && parseInt(this.props.match.params.optionId) === 1){
              localStorage.setItem('StockTransferItemFromBin',JSON.stringify(this.state.selectedDistNumbers));
              localStorage.setItem('selectedFromBinBatch',JSON.stringify(this.state.selectedBinLocQty));
              //alert("s2");
              
            }
            else{
            localStorage.setItem('StockTransferItemFromBin',this.state.scanned_ean_code);
            //localStorage.setItem('selectedFromBinBatch',localStorage.getItem('StockTransferBinBatch'));
            }
            
                if(!localStorage.getItem('StockTransferItemDocEntry')){
                localStorage.setItem('StockTransferItemDocEntry',this.state.itemDet.DocEntry);
                }
                //alert(this.state.batchNumbers);
                if(String(this.props.match.params.id) === 'stockcounting'){
                  localStorage.setItem('StockTransferBinBatch',"");
                  window.location.replace('/stockcounting/commentscreen');
                }
                else{
                  localStorage.setItem('StockTransferBinBatch',this.state.batchNumbers);
                  window.location.replace('/warehouse/stockmovementbinloc/'+this.props.match.params.optionId);   
                } 
            
            }
            else{
              alert("Please select item and Qty!");
            }
        }
      else{
        //alert("s3");
        /*let retString = localStorage.getItem("StockTransferItemFromBin");
        let retArray = JSON.parse(retString);

        console.log( retArray);   */
        //localStorage.setItem('StockTransferItemToWH',"");
        //alert('dd'+this.state.batchNumbers);
            if(this.state.scanned_ean_code){
              this.setState({spinner: true});
              setTimeout(() => {
                let optionId =this.props.match.params.optionId;
                if(parseInt(localStorage.getItem('selectStockTransferType')) === 1 && parseInt(optionId) === 2){
                  localStorage.setItem('StockTransferItemToBin','');
                  localStorage.setItem('StockTransferItemFromBin',this.state.scanned_ean_code);
                }
                else{
                localStorage.setItem('StockTransferItemToBin',this.state.scanned_ean_code);
                localStorage.setItem('StockTransferBinBatch',this.state.batchNumbers);
                }
                //alert('dd2'+localStorage.getItem('StockTransferBinBatch'));
                  //let upDateRes=0;
                  if(parseInt(this.props.match.params.optionId) === 2 || parseInt(this.props.match.params.optionId) === 3){
                    this.setState({directCommentScreen: true});
                    //upDateRes = this.saveStockMovementNewItem();
                    //console.log( upDateRes);

                    let itemCode = localStorage.getItem('StockTransferItemCode').replaceAll("-","/");
                    let itemDet = localStorage.getItem('StockTransferItemDet');
                    let DocEntry = parseInt(localStorage.getItem('StockTransferItemDocEntry'))>0 ?localStorage.getItem('StockTransferItemDocEntry'):0;
                    let fromWarehouse = localStorage.getItem('StockTransferItemFromWH');
                    let fromBin = localStorage.getItem('StockTransferItemFromBin');
                    let itemQty = parseInt(localStorage.getItem('StockTransferItemQty'))>0?localStorage.getItem('StockTransferItemQty') : localStorage.getItem('StockTransferItemQtyForNewDoc');
                    let toWarehouse = localStorage.getItem('StockTransferItemToWH');
                    let batchNumbers = localStorage.getItem('StockTransferBinBatch')?localStorage.getItem('StockTransferBinBatch'):localStorage.getItem('selectedFromBinBatch')

                    let toBin = this.state.scanned_ean_code;
                    
                    let ajaxUrl = '/api/v1/stockmovment/saveStockTransferItem';
                    const isBatchEnabled = parseInt(this.props.match.params.isPolskyNumber)===1?"Y":"N";

                    const jsonbody =  {'user_id': localStorage.getItem('userId'),'username': localStorage.getItem('username'),'StockTransferItemId': localStorage.getItem('StockTransferItemId'),
                    'optionId':optionId,'itemCode':itemCode,'itemDet':itemDet,
                    'isBatchEnabled':isBatchEnabled,'fromPage':'otherPages',
                  'fromWarehouse':fromWarehouse,'fromBin':fromBin,'batchNumbers':batchNumbers,
                  'itemQty':itemQty,'toWarehouse':toWarehouse, 'toBin':toBin,'DocEntry':DocEntry};
                  console.log( jsonbody);
                  //alert('dfs1');
            
                //alert(ajaxUrl);
                this.setState({spinner: true});
                if(itemCode.length > 0){
                  
                          fetch(ajaxUrl, {
                            method: 'POST',
                            headers: {
                              'Content-Type': 'application/json',
                              Authorization: `Token ${localStorage.getItem('token')}`
                            },
                            body:JSON.stringify(jsonbody)
                          })
                            .then(res => res.json())
                            .then(data => {
                              console.log( "Stock Item Save:");
                              console.log( data.docdet);
            
                              this.setState({spinner: false});     
            
                              //localStorage.setItem('StockTransferItemCode','');
                              
                              localStorage.setItem('StockTransfertemDet','');
                              //localStorage.setItem('StockTransferItemFromBin','');                              
                              localStorage.setItem('StockTransferItemToWH','');                              
                                                      
                              
                              //this.state.setPrevPage === 'Stockmovementitem' &&  optionId
                              //alert(localStorage.getItem('selectStockTransferType'));
                              //alert(optionId);
                              if(parseInt(localStorage.getItem('selectStockTransferType')) === 1 && parseInt(optionId) === 2){
                                this.setState({ binUpdateStatus: 0 });
                                localStorage.setItem('StockTransferItemId',data.docdet)
                                //localStorage.setItem('StockTransferItemId',2)
                                localStorage.setItem('StockTransferIItemFromWH',toWarehouse);
                                localStorage.setItem('StockTransferItemToWHWithoutBinStatus',1);
                              window.location.replace('/warehouse/stockmovementlist/'+optionId); 
                              }
                              else{
                              this.setState({ binUpdateStatus: 1 });
                              localStorage.setItem('ifCheckCurrentStockItems',0);     
                              localStorage.setItem('StockTransferItemQty',0);
                              localStorage.setItem('StockTransferIItemFromWH','');
                              localStorage.setItem('StockTransferItemToBin','');
                              window.location.replace('/stockmovement/commentscreen/'+this.props.match.params.optionId); 
                              }
                          }
                          ).catch(error => {
                            
                            console.log( error);
                            this.setState({ binUpdateStatus: 2 });
                            this.setState({spinner: false});
                            //return 2; 
                        });

                      }

                  }
                  else{ // Update item QTY and WH/Bins into DB
                    this.setState({directCommentScreen: true});
                    console.log( localStorage.getItem('StockTransferBinBatch'));
                    this.updateStockMovementBins();
                    //console.log( 'ssddfdf'+chkResponse);

                  }
                  }, 2500); 
                  console.log(this.state.binUpdateStatus);
                  //alert(this.state.binUpdateStatus);
                  if(parseInt(this.state.binUpdateStatus) === 1){
                  this.setState({spinner: false});
                  //alert();
                  //if(parseInt(upDateRes) === 1){
                  //window.location.replace('/stockmovement/commentscreen/'+this.props.match.params.optionId); 
                  }
                  //else{
                    //alert("There is an error during the submission!");
                  //}
            }
            else{
              alert("Please select Bin Location!");
            }
        
          }
    
  };

  updateStockMovementBins = () => {
    console.log( localStorage.getItem('StockTransferBinBatch'));  
    //alert(localStorage.getItem('StockTransferItemToWH'));
    let ToBin = localStorage.getItem('StockTransferItemToBin');
    if(ToBin){      
      //alert(localStorage.getItem('StockTransferBinBatch'));     
      const isBatchEnabled = parseInt(this.props.match.params.isPolskyNumber)===1?"Y":"N";
      const postData = {
        user_id: localStorage.getItem('userId'),
        StockTransferOptionId: this.props.match.params.optionId,
        StockTransferItemId: localStorage.getItem('StockTransferItemId'),
        StockTransferItemIsPolsky: localStorage.getItem('StockTransferItemIsPolsky'),
        StockTransferItemCode: localStorage.getItem('StockTransferItemCode'),
        StockTransferItemQty: localStorage.getItem('StockTransferItemQty'),
        

        StockTransferItemFromWH: localStorage.getItem('StockTransferItemFromWH'),
        StockTransferItemFromBin: localStorage.getItem('StockTransferItemFromBin'),
        StockTransferItemToWH: localStorage.getItem('StockTransferItemToWH'),
        StockTransferItemToBin: localStorage.getItem('StockTransferItemToBin'),
        StockTransferItemDocEntry: localStorage.getItem('StockTransferItemDocEntry'),
        isBatchEnabled:isBatchEnabled,
        StockTransferBinBatch: localStorage.getItem('StockTransferBinBatch')?localStorage.getItem('StockTransferBinBatch'):localStorage.getItem('selectedFromBinBatch')
        
      };
      console.log( localStorage.getItem('StockTransferBinBatch'));  
      console.log( postData);  
      //alert('dfs');
      fetch('/api/v1/stockmovment/updateSTockTransferItemBin', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${localStorage.getItem('token')}`
        },
        body: JSON.stringify(postData)
      })
        .then(res => res.json())
        .then(data => {
          console.log( data);  
          //localStorage.setItem('pageFrom','');          
          //localStorage.setItem('StockTransferItemId','');
          //localStorage.setItem('StockTransferItemIsPolsky','');
          //localStorage.setItem('StockTransferItemCode','');
          localStorage.setItem('StockTransferItemQty','');
          localStorage.setItem('StockTransferItemFromWH','');
          localStorage.setItem('StockTransferItemFromBin','');
          localStorage.setItem('StockTransferItemToWH','');
          localStorage.setItem('StockTransferItemToBin','');
          localStorage.setItem('selectedFromBinBatch','');
          localStorage.setItem('StockTransferBinBatch','');
          console.log( 11);
          this.setState({ binUpdateStatus: 1 });
          if(this.state.directCommentScreen === true){
            window.location.replace('/stockmovement/commentscreen/'+this.props.match.params.optionId); 
          }
          return 1;         
        
      }
      )
      .catch(error => {
        console.log( error);
        this.setState({ binUpdateStatus: 2 });
        //return 2; 
        });  


    }
    else{
      alert("Please enter the comments!");
    }
        }

  saveStockMovementNewItem = () => {
    let itemCode ='';
    let itemQty ='';
    let batchNumbers ='';

       itemCode = localStorage.getItem('StockTransferItemCode').replaceAll("-","/");
      let itemDet = localStorage.getItem('StockTransferItemDet');
      let DocEntry = parseInt(localStorage.getItem('StockTransferItemDocEntry'))>0?localStorage.getItem('StockTransferItemDocEntry'):localStorage.getItem('StockTransferMultipleDocEntry');
      let fromWarehouse = localStorage.getItem('StockTransferItemFromWH');
      let fromBin = localStorage.getItem('StockTransferItemFromBin');
      itemQty = parseInt(localStorage.getItem('StockTransferItemQty'))>0?localStorage.getItem('StockTransferItemQty') : localStorage.getItem('StockTransferItemQtyForNewDoc');

      let toWarehouse = localStorage.getItem('StockTransferItemToWH');
      batchNumbers = localStorage.getItem('StockTransferBinBatch');
      let toBin = this.state.scanned_ean_code;
      let optionId =this.props.match.params.optionId;
      const isBatchEnabled = parseInt(this.props.match.params.isPolskyNumber)===1?"Y":"N";
      let fromPage = String(this.props.match.params.id);

        if(fromPage === 'stockcounting'){
            //localStorage.setItem('StockTransferItemIsPolsky',this.props.match.params.isPolskyNumber);
            itemCode = this.props.match.params.itemcode;
            itemQty = this.state.incnum;
            batchNumbers = "";
        }


      
      let ajaxUrl = '/api/v1/stockmovment/saveStockTransferItem';
      console.log( itemQty);
      console.log( this.state.total_scanned_item);
//alert(batchNumbers);
      const jsonbody =  {user_id: localStorage.getItem('userId'),'username': localStorage.getItem('username'),StockTransferItemId: localStorage.getItem('StockTransferItemId'),
        'optionId':optionId,'itemCode':itemCode,'itemDet':itemDet,
        'isBatchEnabled':isBatchEnabled,'fromPage':fromPage,
      'fromWarehouse':fromWarehouse,'fromBin':fromBin,'batchNumbers':batchNumbers,
      'itemQty':itemQty,'toWarehouse':toWarehouse, 'toBin':toBin,'DocEntry':DocEntry};
      console.log( jsonbody);
    

    //alert(ajaxUrl);
    this.setState({spinner: true});
    if(itemCode.length > 0){
      
              fetch(ajaxUrl, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Token ${localStorage.getItem('token')}`
                },
                body:JSON.stringify(jsonbody)
              })
                .then(res => res.json())
                .then(data => {
                  console.log( data);

                  this.setState({spinner: false});     


                  if(fromPage !== 'stockcounting'){
                  //localStorage.setItem('StockTransferItemCode','');
                  localStorage.setItem('StockTransferIItemFromWH','');
                  localStorage.setItem('StockTransferItemToWH','');
                  }

                  localStorage.setItem('StockTransfertemDet','');
                  //localStorage.setItem('StockTransferItemFromBin','');
                  localStorage.setItem('StockTransferItemQty',0);                  
                  localStorage.setItem('StockTransferItemToBin','');
                  localStorage.setItem('ifCheckCurrentStockItems',0)
                  if(this.state.directCommentScreen){ // save the details directly without Save Button
                    this.setState({ binUpdateStatus: 1 });
                    return 1;
                  }
                  if(fromPage !== 'stockcounting'){
                  //if(data.docNum === 0){
                    if(parseInt(optionId) === 1 ){                     
                    //backUrl = "/stockdocdetails/"+OptionId+'/'+this.state.itemDet.DocNum+'/'+this.state.itemDet.DocEntry;
                    window.location.replace('/stockdocdetails/1/0/'+localStorage.getItem('StockTransferMultipleDocEntry'));  
                      }
                     else if(parseInt(optionId) === 3 ){ 
                      window.location.replace('/stockdocdetails/3/'+DocEntry+'/'+DocEntry);
                    }
                      else{
                    window.location.replace('/stockdocdetails/2/'+DocEntry+'/'+DocEntry);
                      } 
                //window.location.replace('/warehouse/stockmovementlist/2');
                    }
                    else{
                      window.location.replace('/stockcountingdocdetails/'+optionId);
                    }
              }
              ).catch(error => {
                
                console.log( error);
                this.setState({spinner: false});
                this.setState({ binUpdateStatus: 2 });
                return 2; 
            });
    
      } //if

  }

    
    findItem(refName, e) {
   
      //if (event.charCode === 13) { for enter key
      let eanNum = e.target.value;
      
      //e.preventDefault();
      //loader load
      
      //if(eanNum.length >= 13){
        if(eanNum.length >= 5){


      document.getElementById('item_ean').value='';      
      
      //this.setState({ scanned_ean_code: eanNum });
      //alert('s');
     // this.setState({ scanned_ean_code: null });
     //if(eanNum === ''){
      //eanNum = this.state.scanned_ean_code;
     //}
     console.log(eanNum);

          this.scanItemUpdate(eanNum,0);

        } //if

      };
      
      scanItemUpdate(eanNum,isQtyFld) { 
        

        setTimeout(() => {
                   
          const docId = this.props.match.params.id;
          let scanQty =this.state.qtyInput;
          //alert(eanNum); //.replaceAll(" ","-")
          let callUrl = '/api/v1/documents/itemfind/'+docId+'/'+eanNum+'/'+scanQty+'/'+parseInt(isQtyFld);
          fetch(callUrl, {
                  method: 'GET',
                  headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${localStorage.getItem('token')}`
                  }
                })
                  .then(res => res.json())
                  .then(data => {
                    //alert(this.state.input);
                 //console.log( data);
                if( this.state.itemSelectedPrevQty > 0 ){
                this.setState({itemSelectedPrevQty: this.state.itemSelectedPrevQty-1});
                }
                  // document.getElementById('item_ean').value='';
                    //document.getElementById('item_qty_input').value='';
                    if(this.state.itemSelectedPrevId >0 && this.state.itemSelectedPrevQty > 0 ){
                    document.getElementById('itemTap'+this.state.itemSelectedPrevId).setAttribute("style","height: 40px; border: 1px solid green; margin: 3px; padding: 3px; background: rgb(255, 165, 0) none repeat scroll 0% 0%;");  
                    }
                    //else{
                      //document.getElementById('itemTap'+this.state.itemSelectedPrevId).setAttribute("style","height: 70px; border: 1px solid green; margin: 3px; padding: 3px; background: green none repeat scroll 0% 0%;");  
                    //}
                    // this.setState({ scanned_ean_code: null })
                  if(data.id > 0 ){
                   // this.setState({ scanned_ean_code: null });
                    this.findDocument();
                    //console.log( data.id);
                  }
                  else{
                    
                    
                    this.setState({ input: "" });
                    this.setState({ scanned_ean_code: null });

                    alert("EAN Code not exist!");
                    //console.log( data.status);
                    //alert('Invalid EAN!');
                    this.errors = true;
                  }
                  this.setState({ input: "" });
                 // this.setState({ scanned_ean_code: null });
                  this.setState({ itemSelected: false });
                  //alert(this.state.input);
                }
                )
                .catch(error => {
                  this.setState({ input: '' });
                  //alert(this.state.input);
                  this.setState({ scanned_ean_code: null });
                  alert("EAN Code not exist!");
              });  

      

      }, 500);

      }
     
    componentDidMount() {
      if (localStorage.getItem('token') === null) {
        window.location.replace('/cardlogin');
      } 
      //const { studentId } = this.props.match.params;
      //console.log( this.props.match.params);
      //this.refreshList();
     //alert(localStorage.getItem('StockTransferDefaultBin')); 01-V-01
      
            
      this.findStockQtyBinLoc();

      


      
      }
     
    

        onChangeKeyboard = inputk => {
          //console.log('SB1: '+this.state.input);
          //console.log('SB2: '+inputk);
          
          this.setState({
            input: inputk
          });
          //console.log("Input changed", inputk);
        };
      
        onKeyPress = button => {
          this.setState({ upScanQty: false });
          //console.log("Button pressed", button);
      
          /**
           * If you want to handle the shift and caps lock buttons
           */
           if(button === "Close"){
            this.setState({ input: '' });
            this.keyPadClose();
           }
         // if (button === "{shift}" || button === "{lock}") this.handleShift();
            if(button === "{enter}"){
              
              //handlefindItem: this.findItem.bind(this, 'DocumentItemFind')
                //this.setState({ handlefindItem: this.findItem.bind(this.state.input, 'DocumentItemFind') });
                //this.findItem.bind(this.state.input, 'DocumentItemFind')
                //this.findItem(this.state.input, 'DocumentItemFind');
                let currKeyEan = this.state.input;
                //console.log('Keypad1: ',this.state.input);
                
                this.setState({ input: '' });
                
                //console.log('Keypad2: ',currKeyEan);
                this.scanItemUpdate(currKeyEan,0);
                this.keyPadClose();
                
                
            }
          
        };
        

        onChangeQty = input2 => {
          this.setState({ upScanQty: false });
          
          //alert(input2);
              this.setState({
                input2: input2
              });
              //console.log("Input changed", input2);
        };
      
             
      
        handleShift = () => {
          let layoutName = this.state.layoutName;
      
          this.setState({
            layoutName: layoutName === "default" ? "shift" : "default"
          });
        };
      
        onChangeInput = event => {
          let input = event.target.value;
          //alert(input);
          console.log("Button pressed", input);
          this.setState(
            {
              input: input
            },
            
            () => {
              return true;
              //this.keyboard.setInput(input);
              //this.keyboard.setState({ input: input });
            }
          );
        };

        
        onChangeEanInput = (e) => {
          //e.preventDefault();
          this.setState({ hasQty: false });
          this.setState({ upScanQty: false });
          this.setState({keyboardVisibilityQty: false});
          this.setState({keyboardVisibilityScanQty: false});

          if(this.state.keyboardVisibility === false){
            this.setState({keyboardVisibility: true});
          }
          else{
            this.setState({keyboardVisibility: false});
          }
        
      };

        onChangeQtyInput = e => {
         // alert('s');
         this.setState({ upScanQty: false });
         
          this.setState({keyboardVisibility: false});
          this.setState({keyboardVisibilityScanQty: false});
          if(this.state.keyboardVisibilityQty === false){
            this.setState({keyboardVisibilityQty: true});
           }
           else{
            this.setState({keyboardVisibilityQty: false});
           }
        
      };
      
      keyPadClose = () => {
        // alert('s');
         this.setState({keyboardVisibility: false});
         this.setState({keyboardVisibilityQty: false});
         this.setState({keyboardVisibilityScanQty: false});
         
         const focusField = document.querySelector(
          `input[name=item_ean]`
        );
          focusField.focus();
       
     };
     onBinEnWHTap = (e,WhsCode) => {
      localStorage.getItem('StockTransferItemToWH',WhsCode);
      localStorage.getItem('StockTransferItemToBin','NA');
      let idSeltd = WhsCode;
      if(this.state.itemSelectedPrevId){
        if(document.getElementById('itemTap'+this.state.itemSelectedPrevId)){
        document.getElementById('itemTap'+this.state.itemSelectedPrevId).removeAttribute("style");  
        document.getElementById('itemTap'+this.state.itemSelectedPrevId).setAttribute("style","height: 40px; border: 1px solid green; margin: 3px; padding: 3px;color: #fff; background: rgb(132, 126, 126) none repeat scroll 0% 0%;");
        //this.setState({itemSelectedPrevId: idSeltd});
      }

      }       

        
          if(idSeltd !==''){
            if(document.getElementById('itemTap'+idSeltd)){
            document.getElementById('itemTap'+idSeltd).removeAttribute("style");  
            document.getElementById('itemTap'+idSeltd).setAttribute("style", "height: 40px; border: 1px solid green;color: #fff; margin: 3px; padding: 3px; background: blue none repeat scroll 0% 0%;");
            //this.setState({itemSelected: true});

          }
          
          this.setState({itemSelected: true});
          }
          else{
            if(document.getElementById('itemTap'+idSeltd)){
           document.getElementById('itemTap'+idSeltd).removeAttribute("style");  
          document.getElementById('itemTap'+idSeltd).setAttribute("style", "height: 40px; border: 1px solid green;color: #fff; margin: 3px; padding: 3px; background: rgb(132, 126, 126) none repeat scroll 0% 0%;");
          //this.setState({itemSelected: false});
            }
            
          }
        this.setState({itemSelectedPrevId: idSeltd});
        console.log(idSeltd);

     }











     onItemTap = (e,idSeltd,WhsCode, binLoc,binQty,batchNum,Indx) => {
      //const itemID = e;

       if(this.state.incnumBinInputOpen === true && this.state.selectedBinLocQty.indexOf(idSeltd) === -1){
        return false;
       }
       
      
      this.setState({keyboardVisibility: false});
      this.setState({itemSelectedAvailQty: binQty});
      this.setState({incnum: binQty});
      localStorage.setItem('StockItemBinQty',binQty);
      const itemEAN = binLoc;
      //let availQty = totalQty-scanQty;  
      //alert(this.state.fromPage);     


      //******************* NEW Code for mul Bin Selection */
     if(( (String(this.state.fromPage) === 'stockTransfer' || String(this.state.fromPage) === 'stockmovementitem') && localStorage.getItem('StockTransferItemToWH') ==="")){
      this.setState({selectedBinLocName: binLoc });
      this.setState({selectedBatchName: batchNum });
      

      let newScanQty = 0;



      let totalQty = this.state.itemDet.Quantity;
      if(String(this.state.fromPage) === 'goodsreceive' || String(this.state.fromPage) === 'stockmovementitem'){
        totalQty = localStorage.getItem('StockTransferItemQtyForNewDoc');
      }

      var selectedBinLocQtyIndex = this.state.selectedBinLocQty.indexOf(idSeltd);
      //console.log(selectedBinLocQtyIndex);
      //console.log(this.state.selectedBinData);
        if (selectedBinLocQtyIndex > -1) { //Make sure item is present in the array, without if condition, -n indexes will be considered from the end of the array.
          //console.log("QTY Index: "+selectedBinLocQtyIndex);

          //console.log(this.state.customSelectedBinQty[selectedBinLocQtyIndex]);
          //console.log(parseInt(this.state.total_scanned_item));
          //console.log(this.state.selectedBinLocQty.splice(selectedBinLocQtyIndex, 1));
          this.state.selectedDistNumbers.splice(selectedBinLocQtyIndex, 1);  
          this.state.selectedBinLocQty.splice(selectedBinLocQtyIndex, 1);        
          newScanQty = parseInt(this.state.total_scanned_item) - parseInt(this.state.customSelectedBinQty[selectedBinLocQtyIndex]);           
          this.setState({itemSelected: false});
          this.setState({incnumBinInputOpen: false});
          //console.log(newScanQty);
          

        }
        else{
      //this.state.selectedBinLocQty.push(binLoc); 
      /*if(this.state.customSelectedBinQty.length <=0){
        this.setState({total_scanned_item: 0});
      }*/
      newScanQty = parseInt(this.state.total_scanned_item) + parseInt(binQty);
      this.setState({itemSelected: true});
      this.setState({incnumBinInputOpen: true});
      
      //console.log("tett");
        }    

        //console.log(this.state.selectedDistNumbers);
        //console.log(this.state.selectedBinData);
      let availQty = totalQty-parseInt(newScanQty); //always check for current bin qty cot check DB scanned qty
      
        let updateReleaseQTY = 0;

        if(availQty > 0){
          //updateReleaseQTY = newScanQty;
          updateReleaseQTY = binQty;
        }
        else{
          updateReleaseQTY = parseInt(binQty) + parseInt(availQty);
          //updateReleaseQTY = this.state.itemSelectedAvailQty;
        }
        this.setState({maxBinQtySelect: parseInt(updateReleaseQTY)?parseInt(updateReleaseQTY):0});
        this.setState({incnum: updateReleaseQTY?parseInt(updateReleaseQTY):0}); //Set initial QTY 

        if(parseInt(updateReleaseQTY) <= 0){
          this.setState({itemSelected: false});
          this.setState({incnumBinInputOpen: false});
          return false;
         }
         const IfBatchEnabled = this.state.isBatchEnabled;
         if (selectedBinLocQtyIndex > -1) { //Make sure item is present in the array, without if condition, -n indexes will be considered from the end of the array.
          //alert(idSeltd);
          document.getElementById('itemTap'+idSeltd).removeAttribute("style");  
          document.getElementById('itemTap'+idSeltd).setAttribute("style","color:#ffffff;height: 40px; border: 1px solid green; margin: 3px; padding: 3px; background: rgb(132, 126, 126) none repeat scroll 0% 0%;");

          }
          else{
            //console.log("upQTY:"+updateReleaseQTY);
            
            if(parseInt(IfBatchEnabled) === 1){
              this.state.selectedDistNumbers.push({"actBinLoc":binLoc,"distOrBin":batchNum,"binLocQty":updateReleaseQTY}); 
              this.state.selectedBinLocQty.push(batchNum+'_'+binLoc); 
            }
            else{
              this.state.selectedDistNumbers.push({"actBinLoc":binLoc,"distOrBin":"", "binLocQty":updateReleaseQTY}); 
              this.state.selectedBinLocQty.push(binLoc); 
            }
            //this.state.selectedBinLocQty.push(batchNum+'_'+binLoc); 
            //document.getElementById('itemTap'+batchNum+'_'+binLoc).setAttribute("style","color:#ffffff;height: 40px; border: 1px solid green; margin: 3px; padding: 3px; background: blue none repeat scroll 0% 0%;");

          }


          var newArry =[];
        var newArry2 =[];
        var newArry3 =[];
        var newArry4 =[];
        let currntTotalUpdatedQty = 0;
        //console.log(this.state.selectedDistNumbers);
        this.state.selectedDistNumbers.forEach(x=>{       
          //if(isPolskyNum === 1){
            var selectedDistNumberIndex = newArry3.indexOf(x.actBinLoc);
            /*if(parseInt(IfBatchEnabled) === 1){
             selectedDistNumberIndex = newArry2.indexOf(x.distOrBin);


            }*/
            //else{
              //var selectedDistNumberIndex = newArry3.indexOf(x.actBinLoc);
            //}
            //console.log(selectedDistNumberIndex);
              if (selectedDistNumberIndex > -1) {
              //newArry.push({"actBinLoc":x.actBinLoc,"distOrBin":x.distOrBin,"binLocQty":x.binLocQty});
              newArry.splice(selectedDistNumberIndex, 1);
              newArry2.splice(selectedDistNumberIndex, 1);
              newArry3.splice(selectedDistNumberIndex, 1);
              newArry4.splice(selectedDistNumberIndex, 1);

              //console.log(newArry.splice(selectedDistNumberIndex, 1));
              currntTotalUpdatedQty = currntTotalUpdatedQty - parseInt(this.state.customSelectedBinQty[selectedBinLocQtyIndex]);
              
            }
              else{
                //console.log(IfBatchEnabled);
                newArry2.push(x.distOrBin);
                newArry3.push(x.actBinLoc);
                //console.log("upQTY2:"+x.binLocQty);
                if(parseInt(IfBatchEnabled) === 1){
                  newArry4.push(x.distOrBin);                  
                  newArry.push({"actBinLoc":x.actBinLoc,"distOrBin":x.distOrBin,"binLocQty":x.binLocQty});
                }
                else{
                  newArry.push({"actBinLoc":x.actBinLoc,"distOrBin":"","binLocQty":x.binLocQty});
                }
                currntTotalUpdatedQty = currntTotalUpdatedQty +x.binLocQty;
                //console.log(currntTotalUpdatedQty);
              }
        });      
        //console.log(currntTotalUpdatedQty);
        //console.log(newArry3);
        //console.log(newArry4);
        this.setState({selectedBinData:newArry});
        this.setState({selectedActBinLoc:newArry3});
        this.setState({selectedActDistNum:newArry4});

        
         this.setState({total_scanned_item: parseInt(currntTotalUpdatedQty)});
        this.setState({itemSelectedAvailQty: parseInt(totalQty) - parseInt(currntTotalUpdatedQty)});
        
        console.log(this.state.selectedBinLocQty);
        //console.log(this.state.selectedBatchName);
        //console.log(this.state.total_scanned_item);
        if(this.state.selectedBinLocQty.length <=0){
          this.setState({selectedBinData: []});
        }
        this.setState({batchNumbers: (this.state.selectedBinLocQty ? this.state.selectedBinLocQty: "")});
        console.log(this.state.selectedDistNumbers);
        //alert(this.state.total_scanned_item);
          if(this.state.selectedBinLocQty.length >0){
            //alert("Use input box to update QTY!");
              for (var i=0; i < this.state.selectedBinLocQty.length; i++) {
                //alert(this.state.selectedActDistNum[i]+this.state.selectedActBinLoc[i]);
                //alert(document.getElementById('itemTap'+this.state.selectedActDistNum[i]+this.state.selectedActBinLoc[i]));
                //console.log('nn'+this.state.selectedActDistNum[i]);
                //console.log('nn'+this.state.selectedActBinLoc[i]);
                if(document.getElementById('itemTap'+this.state.selectedBinLocQty[i]) != null){  
                  console.log('nn'+this.state.selectedBinLocQty[i]);
                document.getElementById('itemTap'+this.state.selectedBinLocQty[i]).setAttribute("style","color:#ffffff;height: 40px; border: 1px solid green; margin: 3px; padding: 3px; background: blue none repeat scroll 0% 0%;");
              }
              
            }
          }        

          



      //******************* NEW Code for mul Bin Selection END*/
        }
        else{
          //alert(binQty);
          this.setState({maxBinQtySelect:binQty});
          if(String(this.state.fromPage) !== 'stockTransfer'){
      this.setState({batchNumbers: (batchNum ? batchNum: "")});
          }
          

//return false;
      //alert(binLoc);
     console.log(this.state.itemSelectedPrevId);

      if(this.state.itemSelectedPrevId){
        if(document.getElementById('itemTap'+this.state.itemSelectedPrevId)){
        document.getElementById('itemTap'+this.state.itemSelectedPrevId).removeAttribute("style");  
        document.getElementById('itemTap'+this.state.itemSelectedPrevId).setAttribute("style","height: 40px; border: 1px solid green; margin: 3px; padding: 3px;color: #fff; background: rgb(132, 126, 126) none repeat scroll 0% 0%;");
        //this.setState({itemSelectedPrevId: idSeltd});
      }

      }       

        
          if(idSeltd !==''){
            if(document.getElementById('itemTap'+idSeltd)){
            document.getElementById('itemTap'+idSeltd).removeAttribute("style");  
            document.getElementById('itemTap'+idSeltd).setAttribute("style", "height: 40px; border: 1px solid green;color: #fff; margin: 3px; padding: 3px; background: blue none repeat scroll 0% 0%;");
            //this.setState({itemSelected: true});

          }
          
          this.setState({itemSelected: true});
          }
          else{
            if(document.getElementById('itemTap'+idSeltd)){
           document.getElementById('itemTap'+idSeltd).removeAttribute("style");  
          document.getElementById('itemTap'+idSeltd).setAttribute("style", "height: 40px; border: 1px solid green;color: #fff; margin: 3px; padding: 3px; background: rgb(132, 126, 126) none repeat scroll 0% 0%;");
          //this.setState({itemSelected: false});
            }
            
          }
        this.setState({itemSelectedPrevId: idSeltd});


         
        }
            


      this.setState(
        {
          scanned_ean_code: itemEAN
        }
      );
    
/*
      const focusField = document.querySelector(
        `input[name=item_ean]`
      );      
        focusField.focus();*/


        //if(polskiNumber === null){
          
          
          //this.tapToScan();
        //}
       // else{
          //alert(polskiNumber);
          //this.tapToScan();
          //this.setState({modalShow: true}); 
        //}

        
    };


    updateSeltdItemBinQty = ()=> {

      const IfBatchEnabled = this.state.isBatchEnabled;
      let currntQty = parseFloat(this.state.incnum);
      let totalQty = parseFloat(this.state.itemDet.Quantity);

      if(String(this.state.fromPage) === 'goodsreceive' || String(this.state.fromPage) === 'stockmovementitem'){
        totalQty = localStorage.getItem('StockTransferItemQtyForNewDoc');
      }
      
      this.state.customSelectedBinQty.push(currntQty);

      
        //this.setState({maxBinQtySelect: parseInt(updateReleaseQTY)});
        //this.setState({incnumBin: updateReleaseQTY}); //Set initial QTY 
      var newArryBin =[];
        //var newArry2Bin =[];
        //var newArry3Bin =[];
        //var newArry4 =[];
      let currntTotalUpdatedQty = 0;
      //console.log("Before QTY update:");
      //console.log(this.state.selectedDistNumbers);
      this.state.selectedDistNumbers.forEach(y=>{      
              
              if(parseInt(IfBatchEnabled) === 1){
                if(y.distOrBin === this.state.selectedBatchName && y.actBinLoc === this.state.selectedBinLocName){
                  currntTotalUpdatedQty = currntTotalUpdatedQty +currntQty;
                  newArryBin.push({"actBinLoc":y.actBinLoc,"distOrBin":y.distOrBin,"binLocQty":currntQty});
                }
                else{
                  currntTotalUpdatedQty = currntTotalUpdatedQty +y.binLocQty;
                  newArryBin.push({"actBinLoc":y.actBinLoc,"distOrBin":y.distOrBin,"binLocQty":y.binLocQty});
                }
              }
              else{
                if(y.actBinLoc === this.state.selectedBinLocName){
                  currntTotalUpdatedQty = currntTotalUpdatedQty +currntQty;
                  newArryBin.push({"actBinLoc":y.actBinLoc,"distOrBin":"","binLocQty":currntQty});
                }
                else{
                  currntTotalUpdatedQty = currntTotalUpdatedQty +y.binLocQty;
                  newArryBin.push({"actBinLoc":y.actBinLoc,"distOrBin":"","binLocQty":y.binLocQty});
                }
              }
            //}

            
      });     
      console.log("After QTY update:"); 
      console.log(newArryBin);
      //console.log("After QTY End:"); 
      //console.log(currntTotalUpdatedQty);
      //console.log(newArry4);
      this.setState({selectedBinData:newArryBin});
      this.setState({selectedDistNumbers:newArryBin});


      
        
        this.setState({total_scanned_item: parseInt(currntTotalUpdatedQty)});
        this.setState({itemSelectedAvailQty: parseInt(totalQty) - parseInt(currntTotalUpdatedQty)});

        //console.log("total_scanned_item:"+ this.state.total_scanned_item); 
        //console.log("itemSelectedAvailQty:"+ this.state.itemSelectedAvailQty); 
      
        //console.log(parseInt(totalQty) - parseInt(currntTotalUpdatedQty));
      this.setState({itemSelected: false});
      this.setState({incnumBinInputOpen: false});
    }

    tapToscan = () => {     

        
      this.setState({modalShow2: true});        
  
  
    };

     incNum =()=>{

      //if(this.state.incnum<this.state.totalOrderQty)
      //{
      //this.setState({incnum: Number(this.state.incnum)+1}); 

      if(String(this.state.fromPage) === 'stockTransfer' && localStorage.getItem('StockTransferItemToWH') ==="" ){
      if(parseInt(this.state.maxBinQtySelect) >= Number(this.state.incnum)+1)
      {
      this.setState({incnum: Number(this.state.incnum)+1}); 
      
      }
      else{
        this.setState({incnum: this.state.incnum}); 
      }
      
      }
      else{
        //alert(this.state.fromPage);
        if(String(this.state.fromPage) === 'goodsreceive' || String(this.state.fromPage) === 'stockmovementitem'){
          //alert(this.state.maxBinQtySelect);
          if(parseInt(this.state.maxBinQtySelect) >= parseInt(this.state.incnum)+1){
          this.setState({incnum: parseInt(this.state.incnum)+1}); 
          }
          else{
            this.setState({incnum: this.state.maxBinQtySelect}); 
          }
          //alert(this.state.maxBinQtySelect);
        }
        else{
        this.setState({incnum: Number(this.state.incnum)+1});  
        }
      }
    };
     decNum = () => {
       if(this.state.incnum>0)
       {
        this.setState({incnum: Number(this.state.incnum)-1}); 
       }
    }
    
    handleChange = (e)=>{
   //alert(e.target.value)
     //console.log(localStorage.getItem('StockTransferItemToWH'));

     this.setState({incnum: e.target.value});
     console.log(parseInt(this.state.maxBinQtySelect));
        //console.log( e.target.value);
        console.log('sdsf s'+Number(this.state.incnum));
        //let chkmaxlimit = Number(this.state.incnum);
        
        if(String(this.state.fromPage) === 'stockTransfer' && localStorage.getItem('StockTransferItemToWH') ==="" ){
          //chkmaxlimit = parseInt(this.state.maxBinQtySelect);
        
         if(parseInt(this.state.maxBinQtySelect) >= parseInt(e.target.value)){
        this.setState({incnum: e.target.value});
         }
         else{
           this.setState({incnum: 0});
         }
        }
        else{
          if(String(this.state.fromPage) === 'goodsreceive' || String(this.state.fromPage) === 'stockmovementitem'){
            //alert(this.state.maxBinQtySelect);
            if(parseInt(this.state.maxBinQtySelect) >= parseInt(e.target.value)){
            this.setState({incnum: parseInt(e.target.value)}); 
            }
            else{
              this.setState({incnum: this.state.maxBinQtySelect}); 
            }
            //alert(this.state.maxBinQtySelect);
          }
          else{
          this.setState({incnum: e.target.value});
          }
        }
     
    }
    

  render() {    

    
    

    
    

    


      let webcamHandleChange = (e, result)=>{
        console.log(result);
    
        //alert(result);
        if (result) {      
          this.setState({webcamResult: result.text});
          this.setState({setStopStream: true});        
          this.setState({modalShow2: false});
          //console.log(this.state.items); 
          let formttdString = result.text;
          console.log(formttdString);
          //this.scanItemUpdate(formttdString);
        //localStorage.setItem('sannedValues',result.text);
        this.highlightItem(formttdString.toUpperCase());
        
        }
        else{
          console.log("Not Found");
          //this.setState({webcamResult: "Not Found"});
        }
       }  

    

    function MydModalWithGridSCan(props) {
      //this.callBinlocations();
  //alert(props.onHide)
  //console.log( props.onHide);
      return (
        <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
          
          <Modal.Body className={`show-grid`}>         
           
          <BarcodeScannerComponent
        width={450}
        height={500}
        stopStream={props.StreamStatus}
        onUpdate={webcamHandleChange}
      />
           
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={props.onHide}>Cancel</Button>           
  
          </Modal.Footer>
        </Modal>
      );
    }
    
    if (this.state.spinner) return ( <div className={`mb-4 scanblock-div`}><h1>Loading wait.... </h1></div>);
    if (this.state.items.length <=0 ) return ( <div><div className={`mb-4 scanblock-div`}><h1>There is no Bin Location(s) found for this item!
      </h1></div>           
      {/*&& this.state.BinEnabledWH.length <=0 */}
      <div className={`mb-12 text-center jumbotron-icon panel_footer`}>
                  
                  <Link to={this.state.setPrevPage} >
                    <button >
                    <i className={`fa fa-arrow-left fa-2`} aria-hidden="true"></i>
                    <br />
                    {JSON.parse(sessionStorage.getItem('currentLang'))[54]}  
                    </button>
                    </Link>&nbsp; </div></div>
    );
    return (

      <div>
        <MydModalWithGridSCan show={this.state.modalShow2} onHide={() => this.setState({modalShow2: false})} itemId={this.props.match.params.id} StreamStatus={this.state.setStopStream}/>
            <div className={`mb-2 scanblock-div`}>
              <h1>{ this.props.match.params.itemcode.replaceAll("-","/")}</h1>
              {!String(this.state.fromPage) === 'goodsreceive' && parseInt(this.state.itemDet.Quantity)>0 &&<h1>{ JSON.parse(sessionStorage.getItem('currentLang'))[70]}: {this.state.itemDet.Quantity}</h1>}
              {String(this.state.fromPage) === 'stockTransfer' && localStorage.getItem('StockTransferItemToWH') ==="" &&              <span>
              <h1>{JSON.parse(sessionStorage.getItem('currentLang'))[73]}   {this.state.total_scanned_item < this.state.itemDet.Quantity ? this.state.total_scanned_item : this.state.itemDet.Quantity }</h1>
              <h1>{JSON.parse(sessionStorage.getItem('currentLang'))[69]}   {this.state.itemSelectedAvailQty > 0 ? this.state.itemSelectedAvailQty : 0 }</h1>
               </span>}

               {String(this.state.fromPage) === 'stockmovementitem' && localStorage.getItem('StockTransferItemToWH') ==="" &&              <span>
               {parseInt(localStorage.getItem('StockTransferItemQtyForNewDoc'))>0 &&<h1>{ JSON.parse(sessionStorage.getItem('currentLang'))[70]}: {localStorage.getItem('StockTransferItemQtyForNewDoc')}</h1>}

              <h1>{JSON.parse(sessionStorage.getItem('currentLang'))[73]}   {this.state.total_scanned_item < localStorage.getItem('StockTransferItemQtyForNewDoc') ? this.state.total_scanned_item : localStorage.getItem('StockTransferItemQtyForNewDoc') }</h1>
              <h1>{JSON.parse(sessionStorage.getItem('currentLang'))[69]}   {this.state.itemSelectedAvailQty > 0 ? this.state.itemSelectedAvailQty : 0 }</h1>
               </span>}


               {String(this.state.fromPage) === 'goodsreceive' &&              <span>
               {parseInt(localStorage.getItem('StockTransferItemQtyForNewDoc'))>0 &&<h1>{ JSON.parse(sessionStorage.getItem('currentLang'))[70]}: {localStorage.getItem('StockTransferItemQtyForNewDoc')}</h1>}

               </span>}
            </div>                    
            

            <div  className={`scanblock-active`}>                
            <div className={`scanblock`}>
                              <div className={`mb-1`}>
                              
                              &nbsp; &nbsp;
                                    <input
                                  name='item_ean'
                                  id='item_ean'
                                  type='text'
                                  ref={(item_ean) => {item_ean && item_ean.focus() }}
                                  placeholder={ JSON.parse(sessionStorage.getItem('currentLang'))[14] +" "+JSON.parse(sessionStorage.getItem('currentLang'))[75]}                                 
                                  onChange={binloc => this.handleSearch(binloc)}
                                />   
                                {/*<button onClick={() =>{this.onChangeEanInput()}} >KeyPad</button>*/}
                                
                          </div>         
                       </div>
                       {parseInt(this.state.totalBinQty)>0 &&<h1>{ JSON.parse(sessionStorage.getItem('currentLang'))[26]}: {this.state.totalBinQty}</h1>}

       
        
              
                  <InfiniteScroll
                    dataLength={this.state.items.length}
                    //next={this.refreshList}
                    //hasMore={this.state.hasMore}
                    //loader={<h4>.</h4>}
                    height={300}

                  >
                            
                                 
                       { this.state.items.map((i, index) => (                          
                               <div >        
                                <Link to='#'  onClick={input => this.onItemTap(input,i.DistNumber ?  i.DistNumber+'_'+i.BinCode : i.BinCode,i.WhsCode,i.BinCode,i.OnHandQty>0?i.OnHandQty:1,i.DistNumber, 1)}>
                                <div className={i.DistNumber ?`itemTap`+i.DistNumber+'_'+i.BinCode:`itemTap`+i.BinCode} id={i.DistNumber?`itemTap`+i.DistNumber+'_'+i.BinCode:`itemTap`+i.BinCode} style={i.status ?  scanned_items : scanned_pending}   >                  
                                
                                {i.DistNumber && 
                                <div className={`items-det`} >
                                      <span className={`todo-title mr-2`} title={index}>{i.DistNumber}</span>                                     
                                </div>
                                  }                                  
                                  
                                  <div className={`items-det`} >
                                  <span className={`todo-title mr-2`} title={index}>{i.BinCode}{}</span>
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  {(!localStorage.getItem('StockTransferItemToWH') || parseInt(this.props.match.params.optionId) === 3) &&
                                  <span className={`todo-title mr-2`} title={index}>
                                  {i.OnHandQty}                                  
                                  </span>  }  

                                  {String(this.state.fromPage) === 'goodsreceive' && (this.state.selectedDefVLoc === i.BinCode && parseInt(localStorage.getItem('StockTransferDefaultBinQty'))>0 && parseInt(this.props.match.params.optionId) === 3) &&
                                  <span className={`todo-title mr-2`} title={index}>
                                  {localStorage.getItem('StockTransferDefaultBinQty')}                                  
                                  </span>  } 
                                            
                                  </div>
                                  

                                  </div>
                                </Link> 
                                </div>
                                ))}      

                           {/* {String(this.state.fromPage) === 'goodsreceive' && parseInt(this.state.items.length) <=0 && this.state.BinEnabledWH.map(j => (                          
                               <div >        
                                <Link to='#'  onClick={input => this.onBinEnWHTap(input,j.WarehouseCode)}>
                                <div className={`itemTap`+j.WarehouseCode} id={`itemTap`+j.WarehouseCode} style={scanned_pending}   >                  
                                
                                
                                <div className={`items-det`} >
                                      <span className={`todo-title mr-2`} title={j}>{j.WarehouseCode}</span>                                     
                                </div>
                                <div className={`items-det`} >
                                      <span className={`todo-title mr-2`} title={j}>{j.WarehouseName}</span>                                     
                                </div>

                                  </div>
                                </Link> 
                                </div>
                           ))}    */}                                  
                      
                        
                  </InfiniteScroll>
                  
                  {this.state.itemSelected && (String(this.state.fromPage) !== 'goodsreceive' && !localStorage.getItem('StockTransferItemToWH')) && 
                  <div className={`col-xl-3`}>
              <div className={`input-group`}>
              <div className={`input-group-prepend`}>
                <button className={`btn btn-outline-primary`} type="button" onClick={() =>{this.decNum()}}>-</button>
              </div>
              <input type="text" id="increamentValue" name="increamentValue" className={`form-control`} value={this.state.incnum} onChange={(i) =>{this.handleChange(i)}}/>
              <div className={`input-group-prepend`}>
                <button className={`btn btn-outline-primary`} type="button" onClick={() =>{this.incNum()}}>+</button>
              </div>
              <div className="input-group-prepend">
                <button className="btn btn-outline-primary" type="button" onClick={() =>{this.updateSeltdItemBinQty()}}>QTY Save</button>
              </div>
            </div>
              
              </div>}
        
                  <div className={`mb-12 text-center jumbotron-icon panel_footer`}>
                  
                  
                  <Link to={this.state.setPrevPage} >
                    <button >
                    <i className={`fa fa-arrow-left fa-2`} aria-hidden="true"></i>                    
                    </button>
                    </Link>                  
                    
                    &nbsp;   
                    {String(this.props.match.params.id) !== 'stockcounting' && 
                    <button onClick={() =>{this.selectDestWareHouse()}} >
                    <i className={`fa fa-arrow-right fa-2`} aria-hidden="true"></i>                              
                    </button>
                      }
                    &nbsp;

                    {(((String(this.state.fromPage) === 'goodsreceive' || String(this.state.fromPage) === 'stockTransfer' || String(this.state.fromPage) === 'stockmovementitem') && localStorage.getItem('StockTransferItemToWH') !=='') || String(this.props.match.params.id) === 'stockcounting') &&
                    <button onClick={() =>{this.saveStockMovementNewItem()}} >
                    <i className={`fa fa-save fa-2`} aria-hidden="true"></i>                              
                    </button>
                    
                    }
                </div>
            </div>

        
      </div>
      
    );

  }

}


export default StockMovementBinLoc;