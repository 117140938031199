// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../fonts/Futura PT Book.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../fonts/Futura PT Book.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../fonts/Futura PT Light.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("../fonts/Futura PT Light.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("../fonts/Futura PT Medium.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("../fonts/Futura PT Medium.woff", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-family:'Futura';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('woff2'),
        url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('woff');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family:'Futura';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format('woff2'),
        url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format('woff');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family:'Futura';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_4___}) format('woff2'),
        url(${___CSS_LOADER_URL_REPLACEMENT_5___}) format('woff');
    font-weight: 500;
    font-style: normal;
}
`, "",{"version":3,"sources":["webpack://./src/ingold/css/fonts.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB;8DACsD;IACtD,gBAAgB;IAChB,kBAAkB;AACtB;AACA;IACI,oBAAoB;IACpB;8DACuD;IACvD,gBAAgB;IAChB,kBAAkB;AACtB;AACA;IACI,oBAAoB;IACpB;8DACwD;IACxD,gBAAgB;IAChB,kBAAkB;AACtB","sourcesContent":["@font-face {\n    font-family:'Futura';\n    src: url('../fonts/Futura PT Book.woff2') format('woff2'),\n        url('../fonts/Futura PT Book.woff') format('woff');\n    font-weight: 400;\n    font-style: normal;\n}\n@font-face {\n    font-family:'Futura';\n    src: url('../fonts/Futura PT Light.woff2') format('woff2'),\n        url('../fonts/Futura PT Light.woff') format('woff');\n    font-weight: 300;\n    font-style: normal;\n}\n@font-face {\n    font-family:'Futura';\n    src: url('../fonts/Futura PT Medium.woff2') format('woff2'),\n        url('../fonts/Futura PT Medium.woff') format('woff');\n    font-weight: 500;\n    font-style: normal;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
