import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import "bootstrap/dist/css/bootstrap.min.css"
import "./ingold/css/App.css"
import "./ingold/css/font-awesome.css"
import "./ingold/css/fonts.css"
import Navbar from './components/layout/Navbar';
import Login from './views/auth/Login';
import Cardlogin from './views/auth/Cardlogin';
import IdleTimer from "./views/auth/IdleTimer";

import Changesetting from './views/app/Changesetting';
import Logout from './views/auth/Logout';
import authCheck from "./views/auth/Authcheck";
import Dashboard from './views/app/Dashboard';

import Pickandpack from './views/app/Pickandpack';
import Docdetails from './views/app/Docdetails';
import StockQtyBinLoc from './views/app/StockQtyBinLoc';



import Goodsreceive from './views/app/Goodsreceive';
import Goodsreceivelist from './views/app/Goodsreceivelist';
import GoodsActiveVendorList from "./components/AllTabs/GoodsReceived/GoodsActiveVendorList";
import Openpurchaseorders from './views/app/Openpurchaseorders';
import GrpoSelection from "./components/AllTabs/GoodsReceived/GrpoSelection";


import Goodsreceipt from './views/app/Goodsreceipt';
import GoodsreceiptOption from "./components/AllTabs/GoodsReceipt/GoodsReceiptOption";
import GoodsReceiptVendorList from "./components/AllTabs/GoodsReceipt/GoodsReceiptVendorList";
import Goodsreceiptdetails from './views/app/Goodsreceiptdetails';
import GoodsRecptGrpoSelection from "./components/AllTabs/GoodsReceipt/CommentScreen";

import Stockmovement from './views/app/Stockmovement';
import StockmovementOption from "./components/AllTabs/StockMovement/StockmovementOption";
import Stockmovementreqlist from './views/app/Stockmovementreqlist';
import Stockmovementlist from './views/app/Stockmovementlist';
import StockDocdetails from './views/app/StockDocdetails';
import StockMovementItem from './views/app/StockMovementItem';
import StockMovementBinLoc from './views/app/StockMovementBinLoc';
import CommentScreen from "./components/AllTabs/StockMovement/CommentScreen";

import StockcountingOption from "./components/AllTabs/StockCounting/StockcountingOption";
import Stockcountinglist from './views/app/Stockcountinglist';
import Stockcountingdocdetails from './views/app/Stockcountingdocdetails';
import StockCountingCommentScreen from "./components/AllTabs/StockCounting/CommentScreen";

import ScanReport from "./components/AllTabs/ScanReport";
import DeliveryScanReport from './views/app/DeliveryScanReport';
import DeliveryScanReportDetails from './views/app/DeliveryScanReportDetails';
import GrpoScanReport from './views/app/GrpoScanReport';

import StockreportOption from "./components/AllTabs/StockReport/StockreportOption";
import Stockreportlist from './views/app/Stockreportlist';
import StockReportQtyBinLoc from './views/app/StockReportQtyBinLoc';

import WareHouse from './views/app/WareHouse';
import ItemWareHouseStock from './views/app/ItemWiseWareHouseStock';

//import Scannertest from './views/test/Scannertest';
import Scanner from "./components/Scanner";



const App = () => {

 if(!sessionStorage.getItem('currentLang')){
  authCheck('en');
 }

 const [isTimeout, setIsTimeout] = useState(false);
  useEffect(() => {
    const timer = new IdleTimer({
      timeout: 1800, //expire after 3600 seconds  
      onTimeout: () => {
        //window.location.replace('/cardlogin');
        fetch('/api/v1/users/auth/logout', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${localStorage.getItem('token')}`
          }
        })
          .then(res => res.json())
          .then(data => {
            console.log(data);
            localStorage.clear();
            window.location.replace('/cardlogin');
          });
        setIsTimeout(true);
      },
      onExpired: () => {
        // do something if expired on load
        //alert('dsdsd');
        setIsTimeout(true);
        
      }
    });

    return () => {
      timer.cleanUp();
    };
  }, []);

  
  
  return (
    <div className='App'>{isTimeout ? this.autoLogout : ""}
      <Router>
        <Navbar />
        <div className="container">
        <Switch>
          <Route path='/login' component={Login} exact />   
          <Route path='/cardlogin' component={Cardlogin} exact />   

            
          <Route path='/dashboard' component={Dashboard} exact />
          <Route path='/pickandpack' component={Pickandpack} exact />
          <Route path="/docdetails/:id" component={Docdetails} exact  />
          

          <Route path='/goodsreceive' component={Goodsreceive} exact />
          <Route path='/goodsreceivelist' component={Goodsreceivelist} exact />
          <Route path='/goodsactivevendorlist' component={GoodsActiveVendorList} exact />
          <Route path="/openpurchaseorders" component={Openpurchaseorders} exact  />
          <Route path='/grposelection' component={GrpoSelection} exact />

          <Route path='/goodsreceipt' component={Goodsreceipt} exact />
          <Route path='/goodsreceiptOption' component={GoodsreceiptOption} exact />
          <Route path='/goodsReceiptVendor' component={GoodsReceiptVendorList} exact />
          <Route path="/goodsreceiptdetails/:id/:docentry" component={Goodsreceiptdetails} exact  />
          <Route path='/goods-recpt-comment/:optionId' component={GoodsRecptGrpoSelection} exact />

          <Route path='/stockmovement' component={Stockmovement} exact />
          <Route path='/stockmovementOption' component={StockmovementOption} exact />
          <Route path='/stockmovementreqlist/:id/:warehousecode' component={Stockmovementreqlist} exact />
          <Route path='/Stockmovementlist' component={Stockmovementlist} exact />
          <Route path='/Stockmovementitem/:optionId/:docId/:docEntry' component={StockMovementItem} exact />
          <Route path="/stockdocdetails/:optionId/:docId/:docEntry" component={StockDocdetails} exact  />
          <Route path="/stockmovementbinloc/:optionId/:id/:isPolskyNumber/:itemcode" component={StockMovementBinLoc} exact  />
          <Route path="/stockmovement/commentscreen/:optionId" component={CommentScreen} exact  />
          
          <Route path='/stockcountingOption' component={StockcountingOption} exact />
          <Route path='/stockcountinglist' component={Stockcountinglist} exact />
          <Route path='/stockcountingdocdetails/:optionId' component={Stockcountingdocdetails} exact />
          <Route path="/stockcounting/commentscreen/:optionId" component={StockCountingCommentScreen} exact  />

          <Route path="/warehouse/:callingfrom/:id" component={WareHouse} exact  />
          <Route path="/itemwisewarehousestock/:callingfrom/:id" component={ItemWareHouseStock} exact  />
          <Route path="/stockQtyBinLoc/:id/:isPolskyNumber/:itemcode/:pageFrom" component={StockQtyBinLoc} exact  />

<Route path='/scanReport' component={ScanReport} exact />
          <Route path='/scanreport/delivery' component={DeliveryScanReport} exact />
          <Route path='/scanreport/deliverydet/:id' component={DeliveryScanReportDetails} exact />
<Route path='/scanreport/grpo' component={GrpoScanReport} exact />

          <Route path='/stockreportOption' component={StockreportOption} exact />
          <Route path='/stockreportlist/:optionId' component={Stockreportlist} exact />
          <Route path='/stockreportqtybinLoc/:optionId/:isPolskyNumber/:itemcode' component={StockReportQtyBinLoc} exact />

          <Route path='/scanner' component={Scanner} exact />

          <Route path='/changesetting' component={Changesetting} exact />
          <Route path='/logout' component={Logout} exact />
        </Switch>
        </div>
      </Router>
    </div>
  );
};

export default App;
