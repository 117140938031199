import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { confirm } from "react-confirm-box";

import BrideNowlogo from '../../ingold/images/main_logo_131X133.png';

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSuper: localStorage.getItem('is_superuser'),
      isAuth: false,
      isNavExpanded: false,
      isNavHide: false
    };
  }

  componentDidMount() {
    if (localStorage.getItem('token') !== null) {
      this.setState({ isAuth: true });
    }
  }

  handleLogout = async (options) => {
    const result = await confirm("Are you sure?", options);
    if (result) {
      fetch('/api/v1/users/auth/logout', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${localStorage.getItem('token')}`
        }
      })
        .then(res => res.json())
        .then(data => {
          console.log(data);
          localStorage.clear();
          window.location.replace('/cardlogin');
        });
    }
  };

  toggleNav = () => {
    this.setState(prevState => ({ isNavExpanded: !prevState.isNavExpanded }));
  };

  render() {
    const { isSuper, isAuth, isNavExpanded, isNavHide } = this.state;

    return (
      <nav className={isNavHide ? "navigation-hide" : "navigation"}>
        <div>
          <a href="/dashboard">
            <img src={BrideNowlogo} alt="WMS Bianco" />
          </a>
          {localStorage.getItem('username') && (
            <span className="user_name">  {JSON.parse(sessionStorage.getItem('currentLang'))[5]} {localStorage.getItem('username')}</span>
          )}
          <button className="hamburger" onClick={this.toggleNav}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="white"
            >
              <path
                fillRule="evenodd"
                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>
        <div className={isNavExpanded ? "navigation-menu expanded" : "navigation-menu"}>
          {isAuth && (
            <ul>
              <li><a href="/pickandpack">{JSON.parse(sessionStorage.getItem('currentLang'))[1]}</a></li>
              <li><a href="/goodsreceive">{JSON.parse(sessionStorage.getItem('currentLang'))[2]}</a></li>
              <li><a href="/stockcountingOption">{JSON.parse(sessionStorage.getItem('currentLang'))[80]}</a></li>
              <li><a href="/stockreportOption">{JSON.parse(sessionStorage.getItem('currentLang'))[81]}</a></li>
              {parseInt(isSuper) === 1 && (
                <li><a href="/scanReport">{JSON.parse(sessionStorage.getItem('currentLang'))[89]}</a></li>
              )}
              <li><Link to='#' className="demo-container" onClick={this.handleLogout}>{JSON.parse(sessionStorage.getItem('currentLang'))[4]}</Link></li>
            </ul>
          )}
        </div>
      </nav>
    );
  }
}

export default Navbar;
