import React,{ Component } from "react";
import { Link } from 'react-router-dom';
//import NumericInput from 'react-numeric-input';
import InfiniteScroll from "react-infinite-scroll-component";

//import Button from 'react-bootstrap/Button';
//import Container from 'react-bootstrap/Container';
//import Modal from 'react-bootstrap/Modal';


  
const scanned_items = {
  height: 50,
  border: "1px solid light gray",
  margin: 3,
  padding: 3,
  background:"#b4b4b4",
  color:"#FFFFFF"
};

const scanned_pending = {
  height: 50,
  border: "1px solid light gray",
  margin: 3,
  padding: 3,
  background:"#b4b4b4",
  color:"#FFFFFF"
};

const small_font = {
  margin: 0,
  padding: 0,
  fontSize:10
};





class DeliveryScanReportDetails extends Component {

  state = {
    items: [],
    Fixeditems: [],
    hasQty: false,
    upScanQty: false,
    spinner:false,
    totalOrderQty: 0,
    qtyButton: JSON.parse(sessionStorage.getItem('currentLang'))[66],
    fullScrenButton: 'Full Screen',
    handlefindItem: this.findItem.bind(this, 'DocumentItemFind'),
    //handlefindItemInput:this.findItem.bind(this, 'DocumentItemFindInput'),
    docItem: [],
    total_scanned_item:0,
    total_released_item:0,
    scanned_ean_code:null,
    hasFullScrn: false,
    visibility: "block",
    showHideEnale: false,
    layoutName: "default",
    input: "",
    input3:"",
    qtyInput:1,
    keyboardVisibility:false,
    keyboardVisibilityQty:false,
    keyboardVisibilityScanQty:false,
    itemSelectedId:0,
    itemSelectedPrevQty:0,
    itemSelected:false,
    itemSelectedAvailQty:0,
    itemSelectedBgColor:0,
    incnum: 0,
    //onNewScanResult: this.onNewScanResult.bind(this, 'ScannedItemFind'),
    webcamResult:"Not Found",
    setStopStream:false,

    AllComments:'',
  };

  findDocument(loadStatus) {
   
    //if (event.charCode === 13) { for enter key    
    const docId = this.props.match.params.id;

    this.setState({ spinner: true });    
              fetch('/api/v1/documents/userscanneddet/'+docId, {
                method: 'GET',
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Token ${localStorage.getItem('token')}`
                }
              })
                .then(res => res.json())
                .then(data => {
                  console.log( data)
                  //document.getElementById("item_ean").value='';
                  this.setState({itemSelected: false}); 
                  const Binrows = [];
                  for (let i = 0; i < data.docItemList.length; i++) {
                      // note: we are adding a key prop here to allow react to uniquely identify each
                      // element in this array. see: https://reactjs.org/docs/lists-and-keys.html
                      Binrows.push(data.docItemList[i]);
                      this.setState({itemSelectedId: data.docItemList[0].picklist_id}); 
                  }

                  this.setState({ spinner: false });
                  //alert(data.total_released_item);
                this.setState({ items: Binrows,Fixeditems:Binrows });
               // this.setState({ items: data.docItemList });
                //console.log( this.state.spinner);
                console.log( data.document)
                
                //this.setState({ todoList: data })

                if(Binrows.length <=0){
                  //alert('Item are already Picked or there is no released QTY for the sales order item(s)!');
                  //window.location.replace('/pickandpack');
                }
                
                
                
              }
              ).catch(error => {
                console.log( error);
                //document.getElementById("item_ean").value='';
                this.setState({itemSelected: false}); 
                this.setState({ spinner: false });

                 
                  //this.setState({ docItem: [], items: [],Fixeditems:[],total_released_item:0, total_scanned_item: 0, AllComments:"" });
                   //alert('Item are already Picked or there is no released QTY for the sales order item(s)!');
                  //window.location.replace('/pickandpack');
              });  
    
      
    
    };

    
       
    findItem(refName, e) {
   
      //if (event.charCode === 13) { for enter key
      let eanNum = e.target.value;
      
      e.preventDefault();
      //loader load
      
      //if(eanNum.length >= 13){
        if(eanNum.length >= 10){
          

     console.log(eanNum);
          if(this.state.scanned_ean_code === null){
          this.scanItemUpdate(eanNum,0);
          }

        } //if

      };      
           
    componentDidMount() {
      if (localStorage.getItem('token') === null) {
        window.location.replace('/cardlogin');
      } 
      //const { studentId } = this.props.match.params;
      //console.log( this.props.match.params);
      this.findDocument(1);
      }      

      onItemTap = (e,idSeltd) => {     
        //alert(idSeltd);
        window.location.replace('/scanreport/delivery/'+idSeltd);

      }
        
     
            
     
  render() {    

    
    
    
    return (

      <div>

                <div className="docinfo pick-list-block-div">
                    
                      <h1>{ JSON.parse(sessionStorage.getItem('currentLang'))[90]}: {this.state.itemSelectedId} </h1>
                    
                        
              </div>

          {this.state.docItem.username !==null &&
            

            <div  className="scanblock-active">               
          
        <hr />
              {/*<div className="scanned-total">
              <div className="mb-2">
                    <h1>{JSON.parse(sessionStorage.getItem('currentLang'))[92]} &nbsp; &nbsp; &nbsp; {JSON.parse(sessionStorage.getItem('currentLang'))[92]} &nbsp; &nbsp; &nbsp;  {JSON.parse(sessionStorage.getItem('currentLang'))[77]} &nbsp;&nbsp;&nbsp;  {JSON.parse(sessionStorage.getItem('currentLang'))[13]} &nbsp;&nbsp;&nbsp;&nbsp;</h1>
                    </div>
                          

              </div>*/}
                  <InfiniteScroll
                    dataLength={this.state.items.length}
                    //next={this.refreshList}
                    //hasMore={this.state.hasMore}
                    //loader={<h4>.</h4>}
                    height={400}

                  >
                    { this.state.spinner && <div className="mb-2 scanblock-div"><h1>Loading wait.... </h1></div>}
                  

                   {  !this.state.spinner && this.state.items.length <=0 && <div className="mb-4 scanblock-div"><h1>There is no item(s)!
                    </h1></div>      }     
                    

                    
                              {this.state.items.map((i, index) => (

                              <div>                                
                                {this.state.showHideEnale === false && (
                                
                                        <div className={`itemTap`+i.id} id={`itemTap`+i.id} style={i.status ?  scanned_items : scanned_pending}  key={i}
                                        >

                                          <div >
                                          <span className={`todo-title mr-2`} title={i.item_code}>
                                          {i.item_code}&nbsp;&nbsp;&nbsp;
                                          &nbsp;&nbsp;&nbsp;&nbsp;                                   
                                          </span>
                                          &nbsp;&nbsp;&nbsp;&nbsp;
                                          <span style={small_font}>                                        
                                          {i.Scannedcode}                                   
                                            </span>
                                          &nbsp;&nbsp;&nbsp;&nbsp;
                                          <span style={small_font}>                                        
                                          {i.scanned_qty}  /  {i.quantity}         
                                                                  
                                            </span></div> 
                                            <div >
                                            
                                          <span style={small_font}>                                     
                                          {i.ScannerLogActivity}                                   
                                            </span>
                                             
                                          
                                            </div>
                                            
                                            
                                        </div>
                                       
                                        
                                          
                                          
                                       
                                      
                                      
                                      
                                  )}
                                </div>
                              ))}
                  </InfiniteScroll>
                  <br/>
                  {this.state.itemSelected && 
                  <div className={`col-xl-3`}>
              <div className={`input-group`}>
              <div className={`input-group-prepend`}>
                <button className={`btn btn-outline-primary`} type="button" onClick={() =>{this.decNum()}}>-</button>
              </div>
              <input type="text" id="increamentValue" name="increamentValue" className={`form-control`} value={this.state.incnum} onChange={(i) =>{this.handleChange(i)}}/>
              <div className={`input-group-prepend`}>
                <button className={`btn btn-outline-primary`} type="button" onClick={() =>{this.incNum()}}>+</button>
              </div>
              <div className="input-group-prepend">
                <button className="btn btn-outline-primary" type="button" onClick={() =>{this.updateSeltdItemQty()}}>Save QTY</button>
              </div>
            </div>
              
              </div>}
        <br />
                  <div className="mb-12 text-center jumbotron-icon panel_footer">
                  
                  <Link to='/scanreport/delivery' >
                    <button >
                    <i className="fa fa-arrow-left fa-2" aria-hidden="true"></i>
                    <br />
                    {JSON.parse(sessionStorage.getItem('currentLang'))[54]}  
                    </button>
                    </Link>                   
                    
                </div>
            </div>

          }
        
      </div>
      
    );

  }

}


export default DeliveryScanReportDetails;