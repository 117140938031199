import React,{ Component } from "react";
import { Link } from 'react-router-dom';


const stockTrans_option = {
  height: 70,
  border: "1px solid green",
  margin: 3,
  padding: 3,
  background:"#FFA500",
  color:"#FFFFFF"
};

class ScanReport extends Component {

  state = {
    layoutName: "default",
    input: "",
    cardCode: "",
    cardName: 'NA',
  };

     
         

  render() {
    
    return (

      <div>
        
            <div className="mb-4" >          
              <div className="mb-12 text-center jumbotron-icon">
              
             <span style={stockTrans_option}> <Link to='/scanreport/delivery' >Delivery Module Scan Report</Link> </span>
                </div>  &nbsp;                                                                                                            
                  <div className="mb-12 text-center jumbotron-icon">                  
                  <span style={stockTrans_option}> <Link to='/scanreport/grpo' >GRPO Module Scan Report</Link> </span>
                  </div>  &nbsp;
                  <div>
                    <br/><br/><br/>
                <div className="mb-12 text-center jumbotron-icon">
                <span style={stockTrans_option}> <Link to='/dashboard' >Back </Link> </span>   
                </div>
                </div>
              </div>

             
       
      </div>

    );

  }

}


export default ScanReport;